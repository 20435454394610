import { IAnyMap, ControlState, globalMatrix, app } from "../../globals";
import { IDropdownOption, IDropdownGroup } from "../../ProjectSettings";
import { ITableControlOptionsColumn } from "../businesslogic";
import { ml } from "../matrixlib";
import { LineType } from "./LineType";

export interface ILineEditorLine {
    id?: string;
    key?: number; // this is just returned to ident if a line in the values
    help: string;
    explanation?: string; // and additional explanation under the help
    value: string;
    type: LineType;
    // for type select
    options?: IDropdownOption[];
    multiple?: boolean;
    groups?: IDropdownGroup[];
    // for tables
    columns?: ITableControlOptionsColumn[];
    // for tables and select
    noEdit?: boolean;
    // for all?
    readonly?: boolean;
    hide?: boolean;
    required?: boolean;
    extraOptions?: IAnyMap;
}

//This class will allow to use the lineEditor outside the config app.
export class LineEditorExt {
    constructor() {
        // super();
    }
    showDialog(
        title: string,
        height: number,
        input: ILineEditorLine[],
        onOk: (update: ILineEditorLine[]) => boolean,
        width?: number,
        showUserAndGroupsSelectWithDialog?: (
            container: JQuery,
            showUsers: boolean,
            showGroups: boolean,
            help: string,
            empty: string,
            selected: string[],
            dialogTitle: string,
            onSelect: (selection: string[]) => void,
        ) => void,
    ): JQueryDeferred<any> {
        let that = this;
        let ok: JQuery;
        let d = $.Deferred();
        app.dlgForm.html("");

        app.dlgForm.addClass("dlg-v-scroll");
        app.dlgForm.removeClass("dlg-no-scroll");

        let ctrls: JQuery[] = [];

        app.dlgForm.dialog({
            autoOpen: true,
            title: title,
            height: height,
            width: width ? width : 720,
            modal: true,
            close: function () {},
            open: function () {
                for (let inp of input) {
                    let canEdit = !inp.readonly;
                    let inlineHelp = inp.explanation ? inp.explanation : "";
                    let dataCy = inp.id ?? inp.help.replace(/\s/g, "_");
                    let ctrl = $(`<div class="controlContainer" data-cy="${dataCy}">`).appendTo(app.dlgForm);
                    switch (inp.type) {
                        case LineType.textline:
                        case LineType.id:
                        case LineType.id_:
                        case LineType.uppercase:
                        case LineType.number:
                            ctrl.plainText({
                                canEdit: canEdit,
                                help: inp.help + (inp.required ? "<sup>*</sup>" : ""),
                                valueChanged: function () {
                                    that.setEnabled(ok, ctrls, input);
                                },
                                parameter: { rows: inp.multiple ? 5 : 1, allowResize: false, inlineHelp: inlineHelp },
                                fieldValue: inp.value,
                            });
                            break;
                        case LineType.json:
                            ctrl.plainText({
                                canEdit: canEdit,
                                help: inp.help + (inp.required ? "<sup>*</sup>" : ""),
                                valueChanged: function () {
                                    that.setEnabled(ok, ctrls, input);
                                },
                                parameter: {
                                    code: "json",
                                    height: 200,
                                    autoFormat: true,
                                    showJSONFormat: true,
                                    hideFullscreen: true,
                                    rows: inp.multiple ? 5 : 1,
                                    allowResize: false,
                                    inlineHelp: inlineHelp,
                                },
                                fieldValue: inp.value,
                            });
                            break;
                        case LineType.color:
                            ctrl.colorPicker({
                                canEdit: canEdit,
                                help: inp.help + (inp.required ? "<sup>*</sup>" : ""),
                                valueChanged: function () {
                                    that.setEnabled(ok, ctrls, input);
                                },
                                parameter: { allowResize: false, inlineHelp: inlineHelp },
                                fieldValue: inp.value,
                            });
                            break;
                        case LineType.richtext:
                            ctrl.richText({
                                canEdit: canEdit,
                                help: inp.help + (inp.required ? "<sup>*</sup>" : ""),
                                valueChanged: function () {
                                    that.setEnabled(ok, ctrls, input);
                                },
                                parameter: { height: 200, requiresContent: inp.required, tiny: true, autoEdit: true },
                                fieldValue: inp.value,
                            });
                            break;

                        case LineType.readonly:
                            ctrl.plainText({
                                canEdit: false,
                                help: inp.help,
                                valueChanged: function () {
                                    // this is not used
                                },
                                parameter: { rows: 1, allowResize: false, inlineHelp: inlineHelp },
                                fieldValue: inp.value,
                            });
                            break;
                        case LineType.boolean:
                            ctrl.checkBox({
                                canEdit: canEdit,
                                help: inp.help,
                                valueChanged: function () {
                                    that.setEnabled(ok, ctrls, input);
                                },
                                fieldValue: inp.value,
                                parameter: { inlineHelp: inlineHelp },
                            });
                            ctrl.removeClass("controlContainer");
                            break;

                        case LineType.table:
                            ctrl.tableCtrl({
                                canEdit: canEdit,
                                help: inp.help,
                                valueChanged: function () {},
                                fieldValue: inp.value,
                                parameter: {
                                    canBeModified: inp.noEdit ? false : true,
                                    create: inp.noEdit ? false : true,
                                    showLineNumbers: false,
                                    readonly_allowfocus: false,
                                    columns: inp.columns,
                                    inlineHelp: inlineHelp,
                                },
                            });
                            break;
                        case LineType.select:
                            ctrl.mxDropdown({
                                controlState: ControlState.FormEdit,
                                canEdit: canEdit,
                                help: inp.help + (inp.required ? "<sup>*</sup>" : ""),
                                fieldValue: inp.value,
                                valueChanged: function () {
                                    that.setEnabled(ok, ctrls, input);
                                },

                                parameter: {
                                    placeholder: "select",
                                    maxItems: inp.multiple ? 100 : 1,
                                    options: inp.options,
                                    groups: inp.groups ? inp.groups : [],
                                    create: inp.noEdit ? false : true,
                                    sort: false,
                                    inlineHelp: inlineHelp,
                                },
                            });
                            break;
                        case LineType.folderselect: {
                            let showOnly = [],
                                showNot = [];
                            if (inp.extraOptions) {
                                if (inp.extraOptions.showOnly && inp.extraOptions.showOnly.length > 0) {
                                    showOnly = inp.extraOptions.showOnly;
                                }

                                if (inp.extraOptions.showNot && inp.extraOptions.showNot.length > 0) {
                                    showNot = inp.extraOptions.showNot;
                                }
                            }
                            // This a hack to make sure that we will parse the ref to create link.
                            addHighlightLink("", globalMatrix.matrixBaseUrl + "/");

                            ctrl.itemSelection({
                                controlState: ControlState.FormEdit,
                                canEdit: canEdit,
                                help: inp.help + (inp.required ? "<sup>*</sup>" : ""),
                                fieldValue: inp.value,
                                valueChanged: function () {
                                    that.setEnabled(ok, ctrls, input);
                                },
                                parameter: {
                                    crossProject: true,
                                    crossProjectAsList: false,
                                    prefix: "Selected folder : ",
                                    buttonName: "Select folder",
                                    singleFolderOnly: true,
                                    showOnly: showOnly,
                                    showNot: showNot,
                                },
                            });
                            break;
                        }
                        case LineType.multiselect:
                            ctrl.multiSelect({
                                afterSelect: function (values: string[]) {
                                    let selection = ctrl.data("selected") ?? [];

                                    values.forEach((value) => {
                                        if (!selection.includes(value)) selection.push(value);
                                    });

                                    ctrl.data("selected", selection);
                                    that.setEnabled(ok, ctrls, input);
                                },
                                afterDeselect: function (values: string[]) {
                                    let selection = ctrl.data("selected") ?? [];
                                    values.forEach((value) => {
                                        let i = selection.indexOf(value);
                                        selection = selection.splice(i, 1);
                                    });
                                    ctrl.data("selected", selection);
                                },
                            });

                            if (inp.options) {
                                inp.options.forEach((opt) => {
                                    ctrl.multiSelect("addOption", { value: opt.id, text: opt.label });
                                });
                                let selection = inp.value ? inp.value.split(",") : [];
                                ctrl.multiSelect("select", selection);
                            }
                            $(
                                "<div class='baseControlHelp' style='margin-top:10px'>" +
                                    inp.help +
                                    (inp.required ? "<sup>*</sup>" : "") +
                                    "</div>",
                            ).insertBefore(ctrl);
                            break;
                        case LineType.userAndGroupSelect:
                            ctrl.data("selected", inp.value ? inp.value.split(",") : []);
                            if (showUserAndGroupsSelectWithDialog) {
                                showUserAndGroupsSelectWithDialog(
                                    ctrl,
                                    true,
                                    true,
                                    inp.help + (inp.required ? "<sup>*</sup>" : ""),
                                    "select users or groups",
                                    inp.value ? inp.value.split(",") : [],
                                    inp.help,
                                    (selected: string[]) => {
                                        ctrl.data("selected", selected);
                                        that.setEnabled(ok, ctrls, input);
                                    },
                                );
                            }
                            break;
                        default:
                            ml.Logger.error("Unknown input type " + inp.type);
                    }
                    ctrls.push(ctrl);
                    if (inp.hide) {
                        ctrl.hide();
                    }
                }
            },
            resizeStop: function (event, ui) {},
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: async function () {
                        let updates: ILineEditorLine[] = await that.getValue(ctrls, input);
                        let issues: string[] = [];
                        $.each(updates, function (idx, update) {
                            if (update.type == LineType.id && !update.value.match(/^[a-z0-9]+$/i)) {
                                issues.push(
                                    input[idx].help +
                                        " is an identifier. It cannot contain anything but letters and digits",
                                );
                            }
                            if (update.type == LineType.id_ && !update.value.match(/^[a-z0-9_-]+$/i)) {
                                issues.push(
                                    input[idx].help +
                                        " is an identifier. It cannot contain anything but letters, digits, dash and underscore",
                                );
                            }
                            if (update.type == LineType.id_ && !update.value.substr(0, 1).match(/^[a-z]+$/i)) {
                                issues.push(input[idx].help + " is an identifier. It must start with a letter");
                            }
                            if (update.type == LineType.number && !update.value.match(/^[0-9]+$/)) {
                                issues.push(input[idx].help + " is a number. It cannot contain anything but digits");
                            }
                            if (update.type == LineType.uppercase && !update.value.match(/^[A-Z]+$/)) {
                                issues.push(
                                    input[idx].help + " is a short id. It cannot contain nothing but uppercase letters",
                                );
                            }
                        });
                        if (issues.length) {
                            alert(issues.join("\n"));
                        } else if (onOk(updates)) {
                            app.dlgForm.dialog("close");
                            d.resolve();
                        }
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        d.reject();
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });

        // get Ok button
        ok = $(".btnDoIt", app.dlgForm.parent());

        // check if at least one shown input is required
        let showsRequired = false;
        $.each(input, function (idx, inp) {
            if (inp.required && !inp.hide) {
                showsRequired = true;
            }
        });
        if (showsRequired) {
            ok.parent().prepend("* required ");
        }

        this.setEnabled(ok, ctrls, input);
        return d;
    }

    static mapToKeys(results: ILineEditorLine[]): ILineEditorLine[] {
        let update: ILineEditorLine[] = [];
        $.each(results, function (idx, uwk) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            update[uwk.key] = uwk;
        });
        return update;
    }
    private async setEnabled(btn: JQuery, ctrls: JQuery[], input: ILineEditorLine[]) {
        if (btn) {
            ml.UI.setEnabled(btn, this.isEnabled(await this.getValue(ctrls, input)));
        }
    }

    private async getValue(ctrls: JQuery[], input: ILineEditorLine[]) {
        let updates = ml.JSON.clone(input);
        let idx = 0;
        for (let ctrl of ctrls) {
            if (ctrl.getController) {
                updates[idx].value = await ctrl.getController().getValueAsync();
            } else {
                updates[idx].value = ctrl.data("selected") ? ctrl.data("selected").join(",") : "";
            }
            idx++;
        }
        return updates;
    }
    private isEnabled(status: ILineEditorLine[]) {
        let hasRequired = false;
        let hasAllRequired = true;
        $.each(status, function (idx, input) {
            if (input.required && !input.hide) {
                hasRequired = true;
                if (!input.value) {
                    hasAllRequired = false;
                }
            }
        });
        return !hasRequired || hasAllRequired;
    }
}
